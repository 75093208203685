/* ------------------------------------------------------------------------ *\
 * Print
\* ------------------------------------------------------------------------ */
/* stylelint-disable selector-no-qualifying-type, selector-max-type */
/* These styles are intended to be global */
@media print {
  /*!
 * Gutenberg
 *
 * MIT Fabien Sa
 * https://github.com/BafS/Gutenberg
 */
  /*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
  /* Document
       ========================================================================== */
  /**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
  html {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    /* 1 */
    line-height: 1.15;
    /* 2 */
    -ms-text-size-adjust: 100%;
    /* 3 */
    -webkit-text-size-adjust: 100%;
    /* 3 */ }
  /* Sections
       ========================================================================== */
  /**
     * Remove the margin in all browsers (opinionated).
     */
  body {
    margin: 0; }
  /**
     * Add the correct display in IE 9-.
     */
  article,
  aside,
  footer,
  header,
  nav,
  section {
    display: block; }
  /**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
  h1 {
    font-size: 2em;
    margin: 0.67em 0; }
  /* Grouping content
       ========================================================================== */
  /**
     * Add the correct display in IE 9-.
     */
  figcaption,
  figure {
    display: block; }
  /**
     * Add the correct margin in IE 8.
     */
  figure {
    margin: 1em 40px; }
  /**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
  hr {
    box-sizing: content-box;
    /* 1 */
    height: 0;
    /* 1 */
    overflow: visible;
    /* 2 */ }
  /**
     * Add the correct display in IE.
     */
  main {
    display: block; }
  /**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
  pre {
    font-family: monospace, monospace;
    /* 1 */
    font-size: 1em;
    /* 2 */ }
  /* Links
       ========================================================================== */
  /**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
  a {
    background-color: transparent;
    /* 1 */
    -webkit-text-decoration-skip: objects;
    /* 2 */ }
  /**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
  a:active,
  a:hover {
    outline-width: 0; }
  /* Text-level semantics
       ========================================================================== */
  /**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
  abbr[title] {
    border-bottom: none;
    /* 1 */
    text-decoration: underline;
    /* 2 */
    text-decoration: underline dotted;
    /* 2 */ }
  /**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
  b,
  strong {
    font-weight: inherit; }
  /**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
  b,
  strong {
    font-weight: bolder; }
  /**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    /* 1 */
    font-size: 1em;
    /* 2 */ }
  /**
     * Add the correct font style in Android 4.3-.
     */
  dfn {
    font-style: italic; }
  /**
     * Add the correct background and color in IE 9-.
     */
  mark {
    background-color: #ff0;
    color: #000; }
  /**
     * Add the correct font size in all browsers.
     */
  small {
    font-size: 80%; }
  /**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline; }
  sub {
    bottom: -0.25em; }
  sup {
    top: -0.5em; }
  /* Embedded content
       ========================================================================== */
  /**
     * Add the correct display in IE 9-.
     */
  audio,
  video {
    display: inline-block; }
  /**
     * Add the correct display in iOS 4-7.
     */
  audio:not([controls]) {
    display: none;
    height: 0; }
  /**
     * Remove the border on images inside links in IE 10-.
     */
  img {
    border-style: none; }
  /**
     * Hide the overflow in IE.
     */
  svg:not(:root) {
    overflow: hidden; }
  /* Forms
       ========================================================================== */
  /**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    /* 1 */
    font-size: 100%;
    /* 1 */
    line-height: 1.15;
    /* 1 */
    margin: 0;
    /* 2 */ }
  /**
     * Show the overflow in IE.
     */
  button {
    overflow: visible; }
  /**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
  button,
  select {
    /* 1 */
    text-transform: none; }
  /**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
    /* 2 */ }
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    /**
       * Remove the inner border and padding in Firefox.
       */
    /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }
  /**
     * Show the overflow in Edge.
     */
  input {
    overflow: visible; }
  /**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box;
    /* 1 */
    padding: 0;
    /* 2 */ }
  /**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto; }
  /**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
  [type="search"] {
    -webkit-appearance: textfield;
    /* 1 */
    outline-offset: -2px;
    /* 2 */
    /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }
  /**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    /* 1 */
    font: inherit;
    /* 2 */ }
  /**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
  fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em; }
  /**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
  legend {
    box-sizing: border-box;
    /* 1 */
    display: table;
    /* 1 */
    max-width: 100%;
    /* 1 */
    padding: 0;
    /* 3 */
    color: inherit;
    /* 2 */
    white-space: normal;
    /* 1 */ }
  /**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
  progress {
    display: inline-block;
    /* 1 */
    vertical-align: baseline;
    /* 2 */ }
  /**
     * Remove the default vertical scrollbar in IE.
     */
  textarea {
    overflow: auto; }
  /* Interactive
       ========================================================================== */
  /*
     * Add the correct display in Edge, IE, and Firefox.
     */
  details {
    display: block; }
  /*
     * Add the correct display in all browsers.
     */
  summary {
    display: list-item; }
  /*
     * Add the correct display in IE 9-.
     */
  menu {
    display: block; }
  /* Scripting
       ========================================================================== */
  /**
     * Add the correct display in IE 9-.
     */
  canvas {
    display: inline-block; }
  /**
     * Add the correct display in IE.
     */
  template {
    display: none; }
  /* Hidden
       ========================================================================== */
  /**
     * Add the correct display in IE 10-.
     */
  [hidden] {
    display: none; }
  * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  *,
  *:before,
  *:after,
  p:first-letter,
  div:first-letter,
  blockquote:first-letter,
  li:first-letter,
  p:first-line,
  div:first-line,
  blockquote:first-line,
  li:first-line {
    background: transparent !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  html {
    font-size: 16px;
    margin: 0;
    padding: 0; }
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background: #fff !important;
    color: #000 !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    margin: 0 auto;
    text-rendering: optimizeLegibility; }
  p,
  blockquote,
  table,
  ul,
  ol,
  dl {
    margin-bottom: 1.5rem;
    margin-top: 0; }
  p:last-child,
  ul:last-child,
  ol:last-child {
    margin-bottom: 0; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.2;
    margin-bottom: 0.75rem;
    margin-top: 0; }
  h1 {
    font-size: 2.5rem; }
  h2 {
    font-size: 2rem; }
  h3 {
    font-size: 1.75rem; }
  h4 {
    font-size: 1.5rem; }
  h5 {
    font-size: 1.25rem; }
  h6 {
    font-size: 1rem; }
  a, a:visited {
    color: #000;
    text-decoration: underline;
    word-wrap: break-word; }
  table {
    border-collapse: collapse; }
  thead {
    display: table-header-group; }
  table,
  th,
  td {
    border-bottom: 1px solid #000; }
  td,
  th {
    padding: 8px 16px; }
  code,
  pre,
  kbd {
    border: 1px solid #bbb;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
    font-size: 85%; }
  code,
  kbd {
    padding: 3px; }
  pre {
    margin-bottom: 1.5rem;
    padding: 10px 12px; }
  pre code,
  pre kbd {
    border: 0; }
  blockquote {
    border: 0;
    border-left: 5px solid #bbb;
    margin-left: 1px;
    padding: 12px 1.5rem; }
  blockquote:first-child {
    margin-top: 0; }
  blockquote p:last-child,
  blockquote ul:last-child,
  blockquote ol:last-child {
    margin-bottom: 0; }
  blockquote footer {
    display: block;
    font-size: 80%; }
  img {
    border: 0;
    display: block;
    max-width: 100% !important;
    vertical-align: middle; }
  hr {
    border: 0;
    border-bottom: 2px solid #bbb;
    height: 0;
    margin: 2.25rem 0;
    padding: 0; }
  dt {
    font-weight: bold; }
  dd {
    margin: 0;
    margin-bottom: 0.75rem; }
  abbr[title],
  acronym[title] {
    border: 0;
    text-decoration: 0; }
  table,
  blockquote,
  pre,
  code,
  figure,
  li,
  hr,
  ul,
  ol,
  a,
  tr {
    page-break-inside: avoid; }
  h2,
  h3,
  h4,
  p,
  a {
    orphans: 3;
    widows: 3; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
    page-break-inside: avoid; }
  h1 + p,
  h2 + p,
  h3 + p {
    page-break-before: avoid; }
  img {
    page-break-after: auto;
    page-break-before: auto;
    page-break-inside: avoid; }
  pre {
    white-space: pre-wrap !important;
    word-wrap: break-word; }
  body {
    padding-bottom: 2.54cm;
    padding-left: 1.8cm;
    padding-right: 1.8cm;
    padding-top: 2.54cm; }
  a[href^='http://']:after, a[href^='https://']:after, a[href^='ftp://']:after {
    content: " (" attr(href) ")";
    font-size: 80%; }
  abbr[title]:after,
  acronym[title]:after {
    content: " (" attr(title) ")"; }
  .page-break,
  .page-break-before {
    page-break-before: always; }
  .page-break-after {
    page-break-after: always; }
  .no-print {
    display: none; }
  a.no-reformat:after {
    content: ''; }
  abbr[title].no-reformat:after,
  acronym[title].no-reformat:after {
    content: ''; }
  a[href^="//"]::after {
    content: " (" attr(href) ")";
    font-size: 80%; } }
